<template>
  <div>
    <b-card
      class="mt-3 sell"
      header="How will you split your trade between each of the client portfolios?"
      inline
    >
      <b-card
        v-for="(element, portfolio, index) in groupArray"
        :key="`${index}+${portfolio}`"
        :header="Object.keys(groupArray)[index]"
        class="sell"
      >
        <b-form inline v-for="(value, keyy, i) in element" :key="[keyy] + [i]">
          <b-form-checkbox
            class="mb-2 mr-sm-2 mb-sm-0 sell"
            v-bind:value="value"
            v-model="selected"
            >{{ value.portfolio_id }}</b-form-checkbox
          >
        </b-form>
      </b-card>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "WizardCustomComponent",
  props: {
    param: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
      portfolios: [],
    };
  },
  computed: {
    groupArray() {
      //Aca se cocina la data que traje del api y despues modifique en dateArray,
      Array.prototype.groupBy = function (prop) {
        // Metodo para agrupar objetos segun distintos valores de la propiedad elegida
        return this.reduce(function (groups, item) {
          let val = item[prop];
          groups[val] = groups[val] || [];
          groups[val].push(item);
          return groups;
        }, {});
      };
      let groupedByThing = this.portfolios.groupBy("client_id");
      return groupedByThing;
    },
  },

  methods: {
    newApiCall() {
      this.param.getter.then((response) => {
        this.portfolios = response.data.data;
      });
    },
    updatestore() {
      this.$store.commit("SET_SELLPORTFOLIOS", this.selected);
    },
  },
  mounted() {
    this.newApiCall();
  },
  watch: {
    selected: {
      immediate: false,

      handler() {
        this.updatestore();
      },
    },
  },
};
</script>

<style scoped lang="css">
.card-header {
  font-weight: 500;
  font-family: "Roboto";
  font-size: 1.4rem;
}

.sell > .card-header {
  background-color: var(--sell-color);
  color: aliceblue;
}
</style>
